import { z } from 'zod';

/** Validation schema for chat meta dto. */
export const chatMetaSchemaDto = z.object({
	/** Conversation security identifier. */
	conversation_sid: z.string(),

	/** Chat ID. */
	id: z.number(),
}).readonly();

/** Chat meta dto. */
export type ChatMetaDto = z.infer<typeof chatMetaSchemaDto>;
