import { Injectable } from '@angular/core';

import { GuestChatCredentialsDto } from '../dtos/chat/guest-chat-credentials.dto';
import { GuestChatCredentials } from '../models/chat/guest-chat-credentials';

import { MapperFromDto } from './mappers';

/** Mapper for guest credentials entities. */
@Injectable({ providedIn: 'root' })
export class GuestChatCredentialsMapper implements MapperFromDto<GuestChatCredentialsDto, GuestChatCredentials> {

	/** @inheritdoc */
	public fromDto(dto: GuestChatCredentialsDto): GuestChatCredentials {
		return {
			sid: dto.conversation_sid,
			token: dto.token,
		};
	}
}
