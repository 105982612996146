import { z } from 'zod';

/** Validation schema for chat authorization response. */
export const chatCredentialsSchemaDto = z.object({

	/** Authorization token for the current user. */
	token: z.string(),
}).readonly();

/** Chat credentials dto. */
export type ChatCredentialsDto = z.infer<typeof chatCredentialsSchemaDto>;
