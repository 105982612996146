import { Message } from '@twilio/conversations';
import { defer, map, Observable } from 'rxjs';

import { ContentType } from '../content-type';
import { assertNonNullWithReturn } from '../../utils/assert-non-null';

/** Media message attachment. */
export type MediaAttachment = Readonly<{

	/** Attachment URL. */
	url: Observable<string>;

	/** Content type. */
	type: ContentType;

	/** Name. */
	name: string;
}>;

/** Chat message. */
export type ChatMessage = Readonly<{

	/** Message instance. */
	message: Message;

	/** Updated date. */
	updatedDate: Date | null;

	/** Body. */
	body: string;

	/** Author ID. */
	authorId: string;

	/** Attachments URLs. */
	attachments: ReadonlyArray<MediaAttachment>;

	/** ID. */
	id: number;
}>;

export namespace ChatMessage {

	/**
		* Map from twilio message instance to domain.
		* @param message Twilio message instance.
		*/
	export function fromMessage(message: Message): ChatMessage {
		return {
			message,
			authorId: assertNonNullWithReturn(message.author),
			body: message.body ?? '',
			id: message.index,
			updatedDate: message.dateUpdated,
			attachments: message.attachedMedia ? message.attachedMedia.map(m => ({
				type: m.contentType as ContentType,
				name: m.filename ?? '',
				url: defer(() => m.getContentTemporaryUrl()).pipe(map(value => value ?? '')),
			})) : [],
		};
	}
}
