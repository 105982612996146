import { z } from 'zod';

/** Validation schema for guest credentials to chat. */
export const guestChatCredentialsDtoSchema = z.object({

	/** Access token. */
	token: z.string(),

	/** Conversation which a guest has access. */
	conversation_sid: z.string(),
});

/** Guest credentials DTO. */
export type GuestChatCredentialsDto = z.infer<typeof guestChatCredentialsDtoSchema>;
