import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { z } from 'zod';

import { ChatCredentials } from '../models/chat/chat-credentials';
import { ChatMapper } from '../mappers/chat.mapper';
import { chatCredentialsSchemaDto } from '../dtos/chat/chat-credentials.dto';
import { User } from '../models/user';
import { ChatDto, chatSchemaDto } from '../dtos/chat/chat.dto';
import { ChatMeta } from '../models/chat/chat-meta';
import { chatMetaSchemaDto } from '../dtos/chat/chat-meta.dto';
import { ChatMetaMapper } from '../mappers/chat-meta.mapper';

import { guestChatCredentialsDtoSchema } from '../dtos/chat/guest-chat-credentials.dto';
import { GuestChatCredentials } from '../models/chat/guest-chat-credentials';
import { GuestChatCredentialsMapper } from '../mappers/guest-chat-credentials.mapper';

import { AppUrlsConfig } from './app-urls.config';

/** Service to manage chats. */
@Injectable({ providedIn: 'root' })
export class ChatApiService {
	private readonly appUrls = inject(AppUrlsConfig);

	private readonly http = inject(HttpClient);

	private readonly chatMapper = inject(ChatMapper);

	private readonly chatMetaMapper = inject(ChatMetaMapper);

	private readonly guestChatCredentialsMapper = inject(GuestChatCredentialsMapper);

	/** Get chat credentials to connect a user with the conversation SDK. */
	public getChatCredentials(): Observable<ChatCredentials> {
		return this.http.post<unknown>(this.appUrls.chat.auth, {}).pipe(
			map(response => chatCredentialsSchemaDto.parse(response)),
			map(chatCredentialsDto => this.chatMapper.fromCredentialsDto(chatCredentialsDto)),
		);
	}

	/**
	 * Get chat meta data.
	 * @param id Chat id.
	 */
	public getChatMeta(id: ChatMeta['id']): Observable<ChatMeta> {
		return this.http.get<unknown>(this.appUrls.chat.details(id)).pipe(
			map(response => chatMetaSchemaDto.parse(response)),
			map(chatMetaDto => this.chatMetaMapper.fromDto(chatMetaDto)),
		);
	}

	/**
		* Generate twilio conversation ID to get information about a chat with a user.
		* @param id ID of the user with whom there is a conversation.
		*/
	public generateConversationSidWithUser(id?: User['id']): Observable<ChatDto> {
		return this.http.post<unknown>(
			this.appUrls.chat.base,
			id ? this.chatMapper.toSidRequestDto(id) : undefined,
		).pipe(
			map(response => chatSchemaDto.parse(response)),
		);
	}

	/**
	 * Generate chat meta of a chat.
	 * @param id User ID. If not provided, the chat will be generated with the current user only without participant.
	 */
	public generateChatMeta(id?: User['id']): Observable<ChatMeta> {
		return this.http.post<unknown>(
			this.appUrls.chat.base,
			id ? this.chatMapper.toSidRequestDto(id) : undefined,
		).pipe(
			map(response => chatMetaSchemaDto.parse(response)),
			map(chatMetaDto => this.chatMetaMapper.fromDto(chatMetaDto)),
		);
	}

	/**
	 * Generate invite token for a chat.
	 * @param chatId Chat id.
	 */
	public generateInviteToken(chatId: ChatMeta['id']): Observable<string> {
		const schema = z.object({
			// eslint-disable-next-line @typescript-eslint/naming-convention
			invite_token: z.string(),
		});

		return this.http.post<unknown>(this.appUrls.chat.invite(chatId), {}).pipe(
			map(response => schema.parse(response).invite_token),
		);
	}

	/**
	 * Get guest access token for twilio using invitation token.
	 * @param token Invitation token.
	 */
	public getGuestChatCredentials(token: string): Observable<GuestChatCredentials> {
		return this.http.post<unknown>(this.appUrls.chat.authGuest, {
			invite_token: token,
		}).pipe(
			map(response => guestChatCredentialsDtoSchema.parse(response)),
			map(dto => this.guestChatCredentialsMapper.fromDto(dto)),
		);
	}
}
