import { z } from 'zod';

import { userDtoSchema } from '../user.dto';

/** Conversation user attributes dto schema. */
export const conversationUserAttributesDtoSchema = userDtoSchema.pick({
	first_name: true,
	last_name: true,
	initials: true,
	preferred_name: true,
	avatar: true,
	username: true,
}).extend({
	// Guest users have string IDs.
	id: z.union([z.string(), z.number()]),

	/** Clinician type. Guest users don't have it. */
	clinician_type: z.string().optional(),
})
	.readonly();

/** Conversation user attributes dto. */
export type ConversationUserAttributesDto = z.infer<typeof conversationUserAttributesDtoSchema>;
