import { Injectable } from '@angular/core';

import { ChatMetaDto } from '../dtos/chat/chat-meta.dto';
import { ChatMeta } from '../models/chat/chat-meta';

import { MapperFromDto } from './mappers';

/** Chat meta mapper. */
@Injectable({ providedIn: 'root' })
export class ChatMetaMapper implements MapperFromDto<ChatMetaDto, ChatMeta> {

	/** @inheritdoc */
	public fromDto(dto: ChatMetaDto): ChatMeta {
		return {
			id: dto.id,
			twilioSid: dto.conversation_sid,
		};
	}
}
