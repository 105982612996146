import { Conversation } from '@twilio/conversations';

import { User } from '../user';
import { DateUtils } from '../../utils/date-utils';
import { assertNonNullWithReturn } from '../../utils/assert-non-null';

/** Conversation user. */
export type ConversationUser = Pick<User,
| 'firstName'
| 'lastName'
| 'preferredName'
| 'initials'
| 'avatarUrl'
| 'username'
> & {

	/** User ID. */
	id: string;

	/** Define whether a user is a guest. */
	isGuest: boolean;

	/** Clinician type. Null is not defined, for example, for guest. */
	clinicianType: string | null;
};

/** Chat. */
export type Chat = Readonly<{

	/** Chat ID. */
	id: number;

	/** Conversation twilio instance. */
	conversation: Conversation;

	/** Human-readable conversation name. */
	name: string;

	/** Participant object of the current user. */
	me: ConversationUser;

	/**
	 * List of participants. Does not include the current user, use `me` for that.
	 * For now we suppose there is only one participant in a chat besides the current user.
	 */
	participants: readonly ConversationUser[];

	/** Unread messages count. */
	unreadMessagesCount: number;

	/** Latest message. */
	latestMessage?: {

		/** Body. */
		body: string;

		/** Date. */
		date: Date | null;
	};
}>;

export namespace Chat {

	/**
		* A function that determines the order of the elements.
		* @param chat1 First chat.
		* @param chat2 Second chat.
		*/
	export function compareFn(chat1: Chat, chat2: Chat): number {
		const chat1LatestMessageDate = chat1.latestMessage?.date;
		const chat2LatestMessageDate = chat2.latestMessage?.date;

		if (chat1LatestMessageDate == null && chat2LatestMessageDate == null) {
			// Indicates that `chat1` and `chat2` are considered equal.
			return 0;
		} else if (chat1LatestMessageDate == null && chat2LatestMessageDate != null) {
			// Indicates that `chat1` should come after `chat2`.
			return 1;
		} else if (chat1LatestMessageDate != null && chat2LatestMessageDate == null) {
			// Indicates that `chat1` should come before `chat2`.
			return -1;
		}

		return DateUtils.compare(
			assertNonNullWithReturn(chat1LatestMessageDate),
			assertNonNullWithReturn(chat2LatestMessageDate),
		);
	}
}
