/* eslint-disable @typescript-eslint/naming-convention */
import { JSONValue } from '@twilio/conversations';
import { z } from 'zod';

const callAttributesSchema = z.object({
	is_finished_call: z.boolean(),
	consultation_id: z.number(),
}).or(
	z.object({
		caller: z.number(),
		consultation_id: z.number(),
		is_incoming_call: z.literal(true),
		is_video_call: z.union([z.literal('true'), z.literal('false')]),
	}),
);

/** Call attributes. */
export type CallAttributes = z.infer<typeof callAttributesSchema>;

/**
 * Check that passed attributes are attributes of Call message.
 * @param attributes Attributes.
 */
export function isCallAttributes(attributes: JSONValue): attributes is CallAttributes {
	const res = callAttributesSchema.safeParse(attributes);
	return res.data !== undefined;
}
